import React, { useEffect, useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { CopyOutlined } from "@ant-design/icons";
import NavBar from "../Navbar";
import { SQMAP } from "sqmap";
import { message } from "antd";

const install = `$ npm  install    @https://storage.googleapis.com/tgmaps/sqmap-1.0.0.tgz   --save`;
const cssCode = `<link   rel="stylesheet"  href="https://storage.googleapis.com/tgmaps/sqmapstyle.css"/>`;
const code = `
import  React  from  'react'
import  {  SQMAP  }  from  "sqmap";

export  default  MyMap  =  ()  =>  {

return (
      <SQMAP
              apiKey = "Your Google Map Key"
              mapContainerStyle = {{
                height: "100vh",
                width: "100vw",
              }}
              center = {{
                lat: 28.65131085843794,
                lng: 77.20766849219008,
              }}
              country = "Country Name(eg:'Singapore')"
              zoomControl = {false}
              EnB = {true}
              FnB = {true}
              Gpoly = {true}
              transportLayer = {true}
              filter = {true}
              userkey = "your sqmap user key"
            />
             )
      } `;

export default function Documentations() {
  const [bgColor, setBgColor] = useState("#0f0f0f");
  const [bgColor1, setBgColor1] = useState("#0f0f0f");
  const [bgColor2, setBgColor2] = useState("#0f0f0f");
  const copyInstall = () => {
    navigator.clipboard.writeText(
      "npm install https://storage.googleapis.com/tgmaps/sqmap-1.0.0.tgz --save"
    );
    setBgColor("#91fbada0");
    message.info("Copied to clipboard");
    setTimeout(() => {
      setBgColor("#1a1409");
    }, 200);
  };

  const quickCopy = () => {
    navigator.clipboard.writeText(copyCode);
    setBgColor1("#91fbada0");
    message.info("Copied to clipboard");
    setTimeout(() => {
      setBgColor1("#1a1409");
    }, 200);
  };

  const copyCSS = () => {
    navigator.clipboard.writeText(cssCode);
    setBgColor2("#91fbada0");
    message.info("Copied to clipboard");
    setTimeout(() => {
      setBgColor2("#1a1409");
    }, 200);
  };
  return (
    <React.Fragment>
      {/* <NavBar /> */}
      <div className="ducument-class">
        <h1 className="doc-head">Documentation</h1>
        <div className="step1">
          <span className="step-span">Step1</span>
          <span className="step-text">
            Signup and Generate a Key. The key will be visible in the User
            Profile page
          </span>
        </div>
        <div className="step1">
          <span className="step-span">Step2</span>
          <span className="step-text">
            Signup on GCP and generate a maps API key.{" "}
            <a
              href="https://developers.google.com/maps/documentation/javascript/get-api-key"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              Click Here
            </a>
          </span>
        </div>
        <div className="step1">
          <span className="step-span">Step3</span>
          <span className="step-text">Integrate SQ-Maps to your frontend.</span>
        </div>
        <div className="step1">
          <span className="step-span">Installation</span>
          <CodeEditor
            value={install}
            language="ts"
            placeholder="Please enter JS code."
            padding={15}
            style={{
              backgroundColor: bgColor,
              borderRadius: 12,
              width: "100%",
              padding: 5,
              lineHeight: 2,
              fontSize: 18,
              fontWeight: 600,
              color: "#fff",
            }}
            disabled={true}
          />
          <CopyOutlined
            className="copy-icon"
            onClick={copyInstall}
            style={{ top: "60%" }}
          />
        </div>
        <div className="step1">
          <span className="step-span">Add css (In your index.html)</span>
          <CodeEditor
            value={cssCode}
            language="html"
            placeholder="css link"
            padding={15}
            style={{
              backgroundColor: bgColor2,
              borderRadius: 12,
              width: "100%",
              padding: 5,
              lineHeight: 2,
              fontSize: 18,
              fontWeight: 600,
              color: "#fff",
            }}
            disabled={true}
          />
          <CopyOutlined
            className="copy-icon"
            onClick={copyCSS}
            style={{ top: "65%" }}
          />
        </div>
        <div className="step1">
          <span className="step-span">Quick Start</span>
          <CodeEditor
            value={code}
            language="js"
            padding={15}
            style={{
              backgroundColor: bgColor1,
              borderRadius: 12,
              width: "100%",
              padding: 5,
              lineHeight: 1.5,
              fontSize: 18,
              fontWeight: 600,
              color: "#fff",
            }}
            disabled={true}
          />
          <CopyOutlined className="copy-icon" onClick={quickCopy} />
        </div>

        <div className="step1" style={{ height: "110vh" }}>
          <span className="step-span">Demo</span>
          <SQMAP
            apiKey="AIzaSyDOu9Ar_56yQEhAtYVhPMywbxb-hbjj43M"
            libraries="places"
            zoom={3}
            mapContainerStyle={{
              height: "100vh",
              width: "100vw",
            }}
            center={{
              lat: 25.2048,
              lng: 55.2708,
            }}
            country="UAE"
            zoomControl={true}
            EnB={true}
            FnB={true}
            Gpoly={true}
            transportLayer={true}
            filter={true}
            userKey="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwNzc0MTMxNywianRpIjoiNmUxNWYzNmUtM2JlMC00NjAwLThkNjYtMDUxMzU1NGRjNjdhIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6IntcImlkXCI6IFwiNDI1NTE3OFwiLCBcImVtYWlsXCI6IFwicmF2aUBnbWFpbC5jb21cIiwgXCJwYXNzd29yZFwiOiBcInJhdmkxMjNcIiwgXCJuYW1lXCI6IFwicmF2aVwiLCBcImNyZWF0ZUF0XCI6IFwiMjAyNC0wMi0xMiAxMjozNToxNy4zMjIwMzNcIiwgXCJ1cGRhdGVkQXRcIjogXCIyMDI0LTAyLTEyIDEyOjM1OjE3LjMyMjA2M1wifSIsIm5iZiI6MTcwNzc0MTMxNywiY3NyZiI6IjZhYTViN2Q5LTc4MGEtNGE3Mi04ODUzLWY5MDAwYTcxZTBiMCJ9.hDZrFoe8QN7aPkx3X0Z1gFLStGXzGe3bZxKRA2cw0ss"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

const copyCode = `
import React from 'react'
import { TGMAP } from "tg_map";

export default MyMap = () => {

return(
      <TGMAP
        apiKey = "Your Google Map Key"
        mapContainerStyle = {{
          height: "100vh",
          width: "100vw",
        }}
        center = {{
          lat: 28.65131085843794,
          lng: 77.20766849219008,
        }}
        country = "Country Name(eg:'Singapore')"
        zoomControl = {false}
        EnB = {true}
        FnB = {true}
        Gpoly = {true}
        transportLayer = {true}
        filter = {true}
        />
      )
  } `;
