import React from "react";
import "./styles/tgstyles.css";
import MainView from "./Component/MainView";

export default function index(props) {
  return (
    <React.Fragment>
      <MainView {...props} />
    </React.Fragment>
  );
}
