import React from "react";
import LandingImage from "../../Images/video/landingIMG.png";

import { useNavigate } from "react-router-dom";
import BlackButton from "../Button/BlackButton";

const VideoSource =
  "https://storage.googleapis.com/travelgeniusimages/travel_genius.mp4";

export default function LandingVideo() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate("/contact_us");
  };

  return (
    <>
      <div className="containerVideo ">
        <div
          dangerouslySetInnerHTML={{
            __html: `<video muted='true' style='width:100%' autoplay='true' loop playsinline src=${VideoSource} poster=${LandingImage}>
        </video>`,
          }}
        ></div>

        <div className="top-left h-100 paddingLR3P">
          <div className="d-flex justify-content-start align-items-center w-50  widthMedia75">
            <div className="">
              <div className="d-flex justify-content-start align-items-center paddingBottom4 textBgColor">
                <h1 className=" text-start font60white700">
                  Powerful location & pricing data insights for the hospitality
                  industry
                </h1>
              </div>
              <div className="d-flex justify-content-start align-items-center paddingBottom5 textBgColor">
                <div className=" font18white400 text-start">
                  Make confident data-driven decisions on property size, style &
                  location + F&B products & pricing
                </div>
              </div>
              {/* <div>
                <BlackButton
                  buttonText="Contact us"
                  submitChange={submitChange}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
