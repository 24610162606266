import "./App.css";
import "./Components/Css/style.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/Store/Store";

import Landing from "./Pages/Landing/Landing";
import Blogs from "./Pages/Blogs/Blogs";
import BlogMore from "./Pages/BlogMore/BlogMore";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PageNotFound from "./Pages/404page/PageNotFound";
import Download from "./Pages/Download/Download";
import TGMAPS from "./Pages/sq-maps";
import Dashboard from "./Pages/sq-maps/Component/Dashboard";

const router = createBrowserRouter([
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: `/blogmore/:id`,
    element: <BlogMore />,
  },
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/contact_us",
    element: <ContactUs />,
  },
  {
    path: "/pagenotfound",
    element: <PageNotFound />,
  },
  {
    path: "/resources",
    element: <Download />,
  },
  {
    path: "/sq-maps",
    children: [
      { index: true, element: <TGMAPS /> },
      // { path: "login", element: <LoginForm /> },
      // { path: "signup", element: <SignupForm /> },
      // { path: "doc", element: <Documentations /> },
      { path: "profile", element: <Dashboard /> },
    ],
  },

  // {
  //   path: "*",
  //   element: <PageNotFound />,
  // },
]);
function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
