import React, { useEffect, useState } from "react";
import { Layout, Typography, message } from "antd";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NavBar from "../Navbar";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {
  CopyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { UserProfile } from "../../../Redux/Actions/Action";
import Spinner from "../../../util/Spinner";

const { Content } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  useEffect(() => {
    callProfile();
  }, []);

  const [showSpi, setShowSpi] = useState(false);
  const [profile, setProfile] = useState(null);
  const [lineData, setLineData] = useState([]);
  const callProfile = async () => {
    setShowSpi(true);
    const res = await UserProfile();
    setProfile(res);
    const dataa = [];
    res?.timestamp.forEach((element, index) => {
      let obj = {};
      obj["name"] = element;
      obj["value"] = index;
      dataa.push(obj);
    });
    setLineData(dataa);
    setShowSpi(false);
  };
  const [bgColor, setBgColor] = useState("#1a1409");

  const userkey = localStorage.getItem("token");
  const star =
    "**********************************************************************************************";
  const [show, setShow] = useState(false);
  //   const barData = [
  //     { name: "Red", value: 12 },
  //     { name: "Blue", value: 19 },
  //     { name: "Yellow", value: 3 },
  //     { name: "Green", value: 5 },
  //     { name: "Purple", value: 2 },
  //     { name: "Orange", value: 3 },
  //   ];

  const copyUserKey = () => {
    navigator.clipboard.writeText(userkey);
    setBgColor("#91fbada0");
    message.info("Copied to clipboard");
    setTimeout(() => {
      setBgColor("#1a1409");
    }, 200);
  };

  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  return (
    <React.Fragment>
      <NavBar
        openLogin={openLogin}
        closeLogin={() => setOpenLogin(false)}
        openSignup={openSignup}
        closeSignup={() => setOpenSignup(false)}
      />
      {showSpi ? <Spinner /> : null}
      <div className="step1" style={{ background: "rgb(44, 44, 44)" }}>
        <span className="step-span">Your key</span>
        <CodeEditor
          value={show ? userkey.match(/.{1,80}/g)[0] + "..." : star}
          language="html"
          placeholder="Please enter JS code."
          padding={15}
          hidden={true}
          style={{
            backgroundColor: bgColor,
            borderRadius: 12,
            width: "100%",
            padding: 5,
            lineHeight: 2,
            fontSize: 18,
            fontWeight: 600,
            color: "#fff",
          }}
          disabled={true}
        />
        {!show ? (
          <EyeInvisibleOutlined
            className="eye-icon"
            style={{ top: "60%" }}
            onClick={() => setShow(true)}
          />
        ) : (
          <EyeOutlined
            className="eye-icon"
            style={{ top: "60%" }}
            onClick={() => setShow(false)}
          />
        )}
        <CopyOutlined
          className="copy-icon"
          onClick={copyUserKey}
          style={{ top: "60%" }}
        />
      </div>
      <Content style={{ padding: "24px", background: "#2c2c2c" }}>
        {/* <Title level={2}>Dashboard</Title> */}

        <div style={{ marginBottom: "24px" }}>
          <Title level={3}>Requests</Title>
          <LineGraph data={lineData} />
        </div>

        <div>
          <Title level={3}>Requests By Month</Title>
          <BarGraph data={lineData} />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;

const BarGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const LineGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
