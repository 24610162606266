import axios from "axios";

const BaseURL = "https://tgapiupdated-yhqg365nqq-uc.a.run.app/";
const MainURL = "https://sq-backendclubed1-cn3husryaa-uc.a.run.app/";
const UserURL = "https://usermodule-yhqg365nqq-uc.a.run.app/";
const BaseURL2 = "https://sq-usermodule-cn3husryaa-uc.a.run.app/";
const SQMAPURL = "https://tg-map-sdk-api-5w4pspiizq-uc.a.run.app/api/v1/";

export const polyService = {
  getBlog: (data) => {
    return axios.post(MainURL + "blogs", data);
  },

  contactUs: (data) => {
    return axios.post(BaseURL2 + "contactus", data);
  },
};

export const getMoreBlogDetails = (data) => {
  return axios.post(MainURL + "blodwithid", data);
};

export const sqMapService = {
  signup: (data) => {
    return axios.post(SQMAPURL + "register", data);
  },
  login: (data) => {
    return axios.post(SQMAPURL + "login", data);
  },
  profile: (data) => {
    return axios.get(SQMAPURL + "profile", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  },
};
