import React, { useState, useEffect } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import BlogMore from "../BlogMore/BlogMore";
import Blogs from "../Blogs/Blogs";
import OurPartners from "../../Components/OurPartners/OurPartners";
import OurProducts from "../../Components/OurProducts/OurProducts";
import Cards from "../../Components/Cards/Cards";
import FAQs from "../../Components/FAQs/FAQs";
import { GetBlog } from "../../Redux/Actions/Action";
import BlogPage from "../Blogs/Components/BlogPage";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import GrowBetter from "../../Components/GrowBetter/GrowBetter";
import TopImage from "../../Components/TopImage/TopImage";
import BlackButton from "../../Components/Button/BlackButton";
import Spinner from "../../util/Spinner";
import LandingVideo from "../../Components/LandingVideo/LandingVideo";
import IHG from "./../../Images/Logo/IHG-Logo.jpg";

function Landing(props) {
  const navigate = useNavigate();

  useEffect(() => {
    let data = {
      type: "type_sqp",
    };
    if (!props.blogs) props.GetBlog(data);
  }, [props.blogs]);

  const routeChange = (e) => {
    const path = `/blogmore/${e}`;
    navigate(path);
  };
  const submitChange2 = (e) => {
    navigate(`/contact_us`);
  };
  const submitChange = (e) => {
    navigate(`/blogs`);
  };
  return (
    <div>
      <NavBar />
      {/* <TopImage /> */}
      <LandingVideo />
      {/* <div className="py-5  paddingLR3P">
        <div className="">
          <div className="text-center d-flex justify-content-center align-items-center py-4">
            <div className="w-75 font36black700">
              Explore our revolutionary location intelligence platforms
            </div>
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4 px-5">
            <div className="w-75 fontUserName">
              Game-changing global data with actionable insights for the
              hospitality industry Create competitve strategies, improve
              profitability and grow market share
            </div>
          </div>
        </div>
      </div> */}
      <OurProducts />
      <Cards />

      <div className="paddingLR3P my-5">
        <div className="">
          <div className="row row-cols-1 row-cols-md-2 g-0 ">
            <div className="col py-5">
              <div className="paddingRight">
                <div className="d-flex justify-content-start align-items-center">
                  <h1 className="font30black700 ">
                    SpotQuest: the world's first location intelligence platform
                    developed specifically for the hospitality industry
                  </h1>
                </div>

                <div className="pt-4 d-flex justify-content-start align-items-center ">
                  <BlackButton
                    buttonText="Contact us"
                    submitChange={submitChange2}
                  />
                </div>
              </div>
            </div>

            <div className="col py-4">
              <div className="paddinRight">
                <div className="pb-3">
                  <p className="font18black600">Our mission</p>
                  <p className="font16gray400">
                    To empower the global hospitality industry with
                    game-changing actionable data insights before making any
                    location-based decision.
                  </p>
                </div>
                <div className="pb-3">
                  <p className="font18black600"> Our story</p>
                  <p className="font16gray400">
                    Driven by a curiosity to improve the way location
                    intelligence is curated and used, our passionate team of
                    data scientists, innovative developers and industry experts
                    developed SpotQuest - the go-to for hospitality location
                    data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FAQs /> */}
      {/* <div className="py-5  paddingLR3P" style={{ backgroundColor: "#F3F4F6" }}>
        <div className="pt-5">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Blogs & News
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">
              Stay Updated: Engage with Our Informative Blog Content
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="paddingLR3P" style={{ backgroundColor: "#F3F4F6" }}>
        <div className="row row-cols-1 row-cols-md-3 g-4 pb-5">
          {!props?.blogs ? (
            <Spinner />
          ) : (
            <>
              <BlogPage
                blogs={props.blogs.slice(0, 3)}
                routeChange={routeChange}
              />
            </>
          )}
        </div>
      </div> */}
      {/* <div
        className="d-flex justify-content-center align-items-center w-100 pb-5 paddingLR3P"
        style={{ backgroundColor: "#F3F4F6" }}
      >
        <div className="pb-5">
          <BlackButton buttonText="View all" submitChange={submitChange} />
        </div>
      </div> */}

      <OurPartners />
      <Testinomial />
      <div className="py-5  paddingLR3P" style={{ backgroundColor: "#E0F2FE" }}>
        <div className="">
          <div className="text-center d-flex justify-content-center align-items-center py-4">
            <div className="w-75 font36black700">
              Relevant data + actionable insights = confident & informed
              decision making
            </div>
          </div>
        </div>
      </div>
      <GrowBetter />

      <Footer />
    </div>
  );
}
const stateToProps = (state) => {
  const { parentState } = state;
  return {
    blogs: parentState.blogs,
  };
};

export default connect(stateToProps, { GetBlog })(Landing);

const Testinomial = () => {
  return (
    <div className="testinomial-container">
      <div className="tetinimial-head">
        <h1>What Our Clients Say</h1>
        <span>
          Discover what our satisfied clients have to say
          <br /> about their experience with our products and services
        </span>
      </div>
      <div className="testinomial-inner">
        <div className="testinomila-title">
          <img src={IHG} alt="" />
          <span className="test-name-title">
            <span style={{ fontWeight: 800 }}>Mr Michael Beechey</span>
            <br />
            <span style={{ fontWeight: 500, fontSize: 12 }}>
              Cluster General manager IHG Hotels & Resorts
            </span>
          </span>
        </div>
        <div className="testinomila-content">
          <blockquote>
            <span>
              SpotQuest's insights have been indispensable in shaping our
              strategic decisions. Their AI-driven platform provided us with
              precise market analysis and location recommendations that helped
              shape our GTM
            </span>
          </blockquote>
        </div>
      </div>
    </div>
  );
};
