import React from "react";
import Res from "../../Images/Image/Restaurant.svg";
import Hotel from "../../Images/Image/Hotel.svg";
import Genius from "../../Images/Image/Genius.svg";
import Right from "../../Images/Image/check-circle.svg";
import BlueButtonWidth100 from "../Button/BlueButtonWidth100";
import { Link } from "react-router-dom";

import Group1 from "../../Images/Image/Group1.jpg";
import Group2 from "../../Images/Image/Group2.jpg";
import Group3 from "../../Images/Image/Group3.jpg";
import Group4 from "../../Images/Image/Group4.jpg";
import BlackButton from "../Button/BlackButton";

export default function Cards() {
  return (
    <>
      {/* <div className="p-3 paddingLR3P" style={{ backgroundColor: "#F3F4F6" }}>
        <div className="row row-cols-1 row-cols-md-2 g-4 mb-5 pb-5">
          <div className="col">
            <div className="card borderNone cardHoverNone">
              <div className="padding30 ">
                <div className="d-flex justify-content-start align-items-center pb-4">
                  <img src={Res} alt="" />
                  <h5 className="cardTitleName px-2">
                    SpotQuest for Restaurants
                  </h5>
                </div>
                <p className="card-text normalText14400 noramlTextheight textOverFlow22">
                  SpotQuest for Restaurants is our Automated Intelligence
                  platform designed for the restaurant industry. With SpotQuest,
                  restaurants gain access to advanced tools for conducting
                  location assessments, pricing analysis, and competition
                  benchmarking. The platform empowers restaurants to proactively
                  position themselves in the market and implement effective
                  pricing strategies to stay one step ahead of the competition.
                </p>
                <hr />

                <div>
                  <p className="cardgrayColorText pb-3">Popular Features</p>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <img src={Right} alt="Right" />
                    <p className=" card-title rightTextMenu">
                      Menu Pricing Comparison
                    </p>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <img src={Right} alt="Right" />
                    <p className="card-title rightTextMenu">
                      Market and Competition Benchmarking
                    </p>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <img src={Right} alt="Right" />
                    <p className="card-title rightTextMenu">
                      Concepts/Cusines to Launch
                    </p>
                  </div>
                </div>
                <Link
                  target="_blank"
                  to="/restaurant"
                  className=""
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <BlueButtonWidth100 buttonText="Learn more" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card borderNone cardHoverNone">
              <div className="padding30">
                <div className="d-flex justify-content-start align-items-center pb-4">
                  <img src={Hotel} alt="" />
                  <h5 className="cardTitleName px-2">SpotQuest for Hotels</h5>
                </div>
                <p className="card-text normalText14400 noramlTextheight textOverFlow22">
                  SpotQuest for Hotels provides a range of specialized services
                  to our esteemed hotel clients, including efficient Feasibility
                  Studies, insightful SWOT analysis, and comprehensive
                  Competition Assessment. By leveraging SpotQuest, hotels can
                  significantly reduce the time required to deliver these
                  critical studies, accelerating the decision-making process for
                  crucial business strategies.
                </p>
                <hr />

                <div>
                  <p className="cardgrayColorText pb-3">Popular Features</p>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <img src={Right} alt="Right" />
                    <p className="card-title rightTextMenu">
                      Location Feasibility Studies
                    </p>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <img src={Right} alt="Right" />
                    <p className="card-title rightTextMenu">SWOT analysis</p>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <img src={Right} alt="Right" />
                    <p className="card-title rightTextMenu">
                      Competitor Analysis
                    </p>
                  </div>
                </div>

                <Link
                  target="_blank"
                  to="/hotels"
                  className=""
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <BlueButtonWidth100 buttonText="Learn more" />
                </Link>
              </div>
            </div>
          </div> */}
      {/* <div className="col">
          <div className="card borderNone">
            <div className="padding30">
              <div className="d-flex justify-content-start align-items-center pb-4">
                <img src={Genius} alt="" />
                <h5 className="cardTitleName">Genius.ai</h5>
              </div>
              <p className="card-text normalText14400 noramlTextheight textOverFlow22">
                Genius.ai is a travel discovery and planning platform that
                enables users to ensure that they exactly have a travel
                experience that they desire. Genius.ai helps travelers to
                discover small neighborhoods within a destination that fulfill
                their travel needs and helps them identify best stay options in
                those neighborhoods.
              </p>
              <hr />

              <div>
                <p className="cardgrayColorText pb-3">Popular Features</p>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Text Search based on your needs
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Identify suitable city neighborhoods
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu"> Book best stays</p>
                </div>
              </div>
              <a
                target="_blank"
                href="https://geni-us-cn3husryaa-uc.a.run.app"
                className=""
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
              </a>
            </div>
          </div>
        </div> */}
      {/* </div>
      </div> */}

      <div className="" style={{ backgroundColor: "#F3F4F6" }}>
        <div className="row flex-row-reverse row-cols-1 row-cols-md-2  g-0   paddingL3P landingPageboxBG">
          <div className="col  d-flex justify-content-center align-items-center ">
            <div className="landingpageImageBox   d-flex justify-content-center marginLeftImage  align-items-center imageCardHeight">
              <img src={Group2} className="imgs borderimage " alt="..." />
            </div>
          </div>
          <div className="col px-3 d-flex justify-content-center align-items-center ">
            <div className="padding30 ">
              <div className="d-flex justify-content-start align-items-center pb-2">
                <img src={Res} alt="" />
                <h5 className="cardTitleName px-2">
                  SpotQuest for Restaurants
                </h5>
              </div>{" "}
              <div className="noramlTextheight">
                <p className="card-text normalText14400 textOverFlow22">
                  Our powerful
                  <span style={{ fontWeight: 1000, color: "#000" }}>
                    {" "}
                    SpotQuest for Restaurants{" "}
                  </span>
                  platform is designed specifically for the restaurant industry
                  when planning new locations, pre-opening, performance and
                  repositioning. Access to these advanced tools will empower
                  restaurants to stay ahead of the competition.
                </p>{" "}
              </div>
              <div>
                <p className="cardgrayColorText pb-2">Popular Features</p>
                <div className="d-flex justify-content-start align-items-center pb-2">
                  <img src={Right} alt="Right" />
                  <p className=" card-title rightTextMenu">
                    Location feasibility studies - market & competition
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-2">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Menu pricing comparison & analysis
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Launch concepts & cuisine research
                  </p>
                </div>
              </div>
              <Link
                target="_blank"
                to="/restaurant"
                className=""
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <BlackButton buttonText="Learn more" />
              </Link>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-0 paddingR3P">
          <div className="col d-flex justify-content-center align-items-center ">
            <div className="landingpageImageBox  d-flex justify-content-center align-items-center marginRightImage imageCardHeight">
              <img src={Group3} className="imgs borderimage " alt="..." />
            </div>
          </div>
          <div className="col px-3 py-4 d-flex justify-content-center align-items-center py-5">
            <div className="padding30">
              <div className="d-flex justify-content-start align-items-center pb-2">
                <img src={Hotel} alt="" />
                <h5 className="cardTitleName px-2">SpotQuest for Hotels</h5>
              </div>
              <div className="noramlTextheight">
                <p className="card-text normalText14400 textOverFlow22">
                  Our powerful
                  <span style={{ fontWeight: 1000, color: "#000" }}>
                    {" "}
                    SpotQuest for Hotels{" "}
                  </span>
                  platform can significantly reduce the time required to deliver
                  critical analysis when planning new hotel location, pre-opening
                  and well as existing hotel performance and repositioning. This
                  will accelerate the decision-making process enabling fast
                  business strategy direction.
                </p>
              </div>

              <div>
                <p className="cardgrayColorText pb-2">Popular Features</p>
                <div className="d-flex justify-content-start align-items-center pb-2">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Location feasibility studies
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-2">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">SWOT analysis</p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Competitor analysis
                  </p>
                </div>
              </div>

              <Link
                target="_blank"
                to="/hotels"
                className=""
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <BlackButton buttonText="Learn more" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
