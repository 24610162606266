import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../Images/Logo/SpotQuest_Logo.svg";
import WhiteButton from "../../../Components/Button/WhiteButton";
import { Modal } from "antd";
import LoginForm from "../Component/Login";
import SignupForm from "../Component/Signup";

export default function NavBar(props) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginModal, showLoginModal] = useState(false);
  const [signupModal, showSingupModal] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate("/sq-maps");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    showLoginModal(false);
    showSingupModal(false);
    props.closeLogin(false);
    props.closeSignup(false);
  };

  const openSignup = () => {
    showLoginModal(false);
    showSingupModal(true);
  };

  const openLoginForm = () => {
    showSingupModal(false);
    showLoginModal(true);
  };
  useEffect(() => {
    if (props.openLogin) {
      showLoginModal(true);
    }
  }, [props.openLogin]);
  useEffect(() => {
    if (props.openSignup) {
      showSingupModal(true);
    }
  }, [props.openSignup]);

  return (
    <React.Fragment>
      <div className="">
        <nav
          className="navbar navbar-expand-lg  paddingLR3P  borderBottomNav"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container-fluid px-0">
            <a className="navbar-brand" href="/">
              <img src={Logo} alt="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end align-items-center"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav my-auto mb-2 mb-lg-0 align-items-center">
                <li className="nav-item dropdown navHover px-2">
                  <Link
                    className="nav-link  fnt16600 paddingLR10 paddingbutton navButtonText"
                    to="/sq-maps"
                    id="navbarDropdownMenuLink "
                    role="button"
                    aria-expanded="false"
                  >
                    Home
                  </Link>
                </li>
                {/* <li className="nav-item dropdown navHover px-2">
                  <Link
                    className="nav-link  fnt16600 paddingLR10 paddingbutton navButtonText"
                    to="/sq-maps/doc"
                    id="navbarDropdownMenuLink "
                    role="button"
                    aria-expanded="false"
                  >
                    Docs
                  </Link>
                </li> */}
                {localStorage.getItem("name") ? (
                  <li className="nav-item dropdown navHover px-2">
                    <Link
                      className="nav-link  fnt16600 paddingLR10 paddingbutton navButtonText"
                      to="/sq-maps/profile"
                      id="navbarDropdownMenuLink "
                      role="button"
                      aria-expanded="false"
                    >
                      Profile
                    </Link>
                  </li>
                ) : null}
                {/* <li className="nav-item navHover px-2">
                <Link
                  className="nav-link navButtonText  paddingbutton"
                  to="/contactus"
                >
                  Profile
                </Link>
              </li> */}
                <li className="nav-item hideOnSmall px-2">
                  <div className="blankLine"></div>
                </li>
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  {localStorage.getItem("name") ? (
                    <span onClick={showModal}>
                      {localStorage.getItem("name")}
                    </span>
                  ) : (
                    // <Link
                    //   className="text-decoration-none navButtonText"
                    //   to="/sq-maps/login"
                    // >
                    <WhiteButton
                      buttonText="Login"
                      submitChange={() => showLoginModal(true)}
                    />
                    // </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        title="Logout"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure?</p>
      </Modal>
      <Modal
        title="Login"
        open={loginModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <LoginForm signup={openSignup} close={handleCancel} />
      </Modal>
      <Modal
        title="Signup"
        open={signupModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <SignupForm login={openLoginForm} close={handleCancel} />
      </Modal>
    </React.Fragment>
  );
}
