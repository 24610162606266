import React, { useEffect, useState } from "react";
import { Anchorme } from "react-anchorme";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../util/Spinner";
import User from "../../Images/Image/user.png";
import { getMoreBlogDetail, GetBlog } from "../../Redux/Actions/Action";
import Linkimg from "../../Images/Image/LinkedInBlack.svg";
import moment from "moment";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import BlackButton from "../../Components/Button/BlackButton";
import BlogPage from "../Blogs/Components/BlogPage";

function BlogMore(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({});
  const [loding, setLoding] = useState(false);
  const [blogId, setBlogId] = useState("");

  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let data = {
      type: "type_sqp"
    };
    if (!props?.blogs) props.GetBlog(data);
  }, [props?.blogs]);
  const submitChange = (e) => {
    navigate("/blogs");
  };
  const routeChange = (e) => {
    navigate(`/blogmore/${e}`);
    callAPI(e);
  };
  useEffect(() => {
    callAPI();
  }, []);
  useEffect(() => {
    if (blogId != "" && props.blogs) {
      setBlogData(props.blogs.filter((item) => item.Id !== blogId));
    }
  }, [blogId, props.blogs]);
  const callAPI = async (e) => {
    setLoding(true);
    let data = { id: e || location?.pathname.split("/")[2] };
    setBlogId(data.id);
    const response = await getMoreBlogDetail(data);
    if (response?.length !== 0) {
      setLoding(false);
      window.scrollTo(0, 0);
      setState(response[0]);
    } else {
      navigate(`/pagenotfound`);
    }
  };

  return (
    <>
      <NavBar />
      <div className="">
        {loding ? (
          <Spinner />
        ) : (
          <>
            <div
              id="my-id2"
              className="pb-4"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="containerVideo  ">
                <div>
                  <img
                    src={state?.titleimage}
                    // style={{ maxHeight: "92vh", minHeight: "63vh" }}
                    className="w-100 blogMenuImage"
                    alt="Loading..."
                  />
                </div>

                <div className="top-left paddingLR3P w-100">
                  <div className="d-flex justify-content-center align-items-center  ">
                    <div className="w-75">
                      <div className="d-flex justify-content-center align-items-center paddingBottom4">
                        <h1 className=" text-center font60white700 textBgColor">
                          {state?.title || ""}
                        </h1>
                      </div>
                      {/* <div className="d-flex justify-content-center align-items-center paddingBottom5">
                        <div className=" font18white400 text-center textOverFlow">
                          {state?.summery || ""}
                        </div>
                      </div> */}
                      <div>
                        <div className="">
                          <div className=" w-100 d-flex justify-content-center align-items-center">
                            <div className="navProfilePicBox d-flex justify-content-center align-items-center">
                              <img
                                src={User}
                                className="navProfilePic"
                                alt="user avatar"
                              />
                            </div>
                          </div>
                          <div className="mx-2">
                            <div className="font18black400Media whiteColor textBgColor">
                              {/* {state?.username} */}SpotQuest
                            </div>
                            <div className="font18black400Media whiteColor textBgColor">
                              {moment(state?.blogdate).format("LL")}

                              {/* <span> . </span>
                              <span>
                                {moment(state?.blogdate)
                                  .startOf("day")
                                  .fromNow()}
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="paddingLR3P">
                <div className="pading20 padding0media">
                  <div className="">
                    <div className="my-4 ">
                      <p className="font16black400">
                        {" "}
                        <Anchorme
                          target="_blank"
                          rel="noreferrer noopener"
                          style={{ textDecoration: "none" }}
                        >
                          {state?.summery || ""}
                        </Anchorme>
                      </p>
                    </div>
                    {!state?.content
                      ? null
                      : state.content.split("\r").map((_, i) => (
                          <>
                            <div key={i} className="my-4">
                              <p className="blogsTextData">
                                {" "}
                                <Anchorme
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  style={{ textDecoration: "none" }}
                                >
                                  {_}
                                </Anchorme>
                              </p>
                            </div>
                            {state.contentimages.length > 0 &&
                            (i === 3 || i === 4) ? (
                              <div className="d-flex justify-content-center align-items-center w-100 mt-4 ">
                                <img
                                  src={
                                    !state.contentimages
                                      ? null
                                      : state.contentimages[i - 3]
                                  }
                                  className="img-fluid imageBorder blogimg "
                                  alt="thumbnail"
                                />
                              </div>
                            ) : null}
                          </>
                        ))}

                    <div className="py-5 linebottom">
                      <p className="font18black400 font600"> Share this post</p>
                      <div className="d-flex justify-content-start align-items-center mt-2">
                        <div className="">
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/spotquestai"
                            className=""
                            rel="noreferrer"
                          >
                            <img
                              src={Linkimg}
                              className="img-fluid imageBorder blogimg"
                              alt="..."
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="my-5">
                      <div className="d-flex ">
                        <div className="navProfilePicBox d-flex justify-content-center align-items-center">
                          <img
                            src={User}
                            className="navProfilePic"
                            alt="user avatar"
                          />
                        </div>
                        <div className="mx-2">
                          <div className="font16black600">
                            {/* {state?.username} */}SpotQuest
                          </div>
                          <div className="font18black400">
                            {moment(state?.blogdate).format("LL")}
                            {/* <span> . </span> */}
                            {/* <span>
                              {moment(state?.blogdate).startOf("day").fromNow()}
                            </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="pt-5 my-5 ">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Blogs & News
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">Related Posts</div>
          </div>
        </div>
        <div className="paddingLR3P">
          <div className="row row-cols-1 row-cols-md-3 g-4 mt-5 mb-4 pb-5 ">
            {!props?.blogs ? (
              <Spinner />
            ) : (
              <>
                <BlogPage
                  blogs={blogData.slice(0, 3)}
                  routeChange={routeChange}
                />
              </>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center w-100 mb-5 pb-5 ">
          <BlackButton buttonText="View all" submitChange={submitChange} />
        </div>
      </div>
      <Footer />
    </>
  );
}

const stateToProps = (state) => {
  const { parentState } = state;
  return {
    blogs: parentState.blogs
  };
};

export default connect(stateToProps, { GetBlog })(BlogMore);
