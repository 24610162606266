import React from "react";
import cardLinkMore from "../../../Images/Image/cardLinkMore.svg";

export default function BlogPage(props) {
  const routeChange = (e) => {
    props.routeChange(e);
  };
  return (
    <>
      {!props.blogs
        ? null
        : props.blogs.map((item, i) => (
            <div
              key={i}
              className="col blogCardBox mb-3"
              onClick={(e) => routeChange(item.Id)}
            >
              <div className="">
                <div className="card cardHover borderNone">
                  <img
                    src={item?.titleimage}
                    className="img-fluid imageBorder  imageblogHeight img300px"
                    alt="thumbnail"
                  />
                  <div className="card-body cardHeight175">
                    <h5 className="card-title cardHeadText textOverFlow2Line">
                      {item?.title}
                    </h5>
                    <p className="card-text cardTextText textOverFlow">
                      {item?.summery}
                    </p>
                  </div>

                  <div className="card-body">
                    <div
                      className="card-link cardLink "
                      style={{ cursor: "pointer" }}
                    >
                      Read more
                      <img src={cardLinkMore} className="" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </>
  );
}
