import {
  polyService,
  getMoreBlogDetails,
  sqMapService,
} from "../Service/Service";
import {
  browserName,
  engineName,
  osName,
  fullBrowserVersion,
  osVersion,
  isMobile,
  isDesktop,
  isTablet,
  isAndroid,
  isIOS,
  mobileModel,
  mobileVendor,
  deviceType,
} from "react-device-detect";

export const GetBlog = (data) => {
  return async (dispatch) => {
    try {
      const response = await polyService.getBlog(data);
      dispatch({
        type: "BLOGS",
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: "BLOGS",
        payload: [],
      });
    }
  };
};
export const getMoreBlogDetail = async (data) => {
  const response = await getMoreBlogDetails(data);
  return response.data;
};

export const Register = async (data) => {
  try {
    const response = await sqMapService.signup(data);

    return response.data;
  } catch (e) {
    return { message: e.message, response: e.response?.data, error: true };
  }
};

export const Loginuser = async (data) => {
  try {
    const response = await sqMapService.login(data);
    return response.data;
  } catch (e) {
    return { message: e.message, response: e.response?.data, error: true };
  }
};

export const UserProfile = async () => {
  try {
    const response = await sqMapService.profile();
    return response.data;
  } catch (e) {
    return { message: e.message, response: e.response?.data, error: true };
  }
};

export const DeviceInfo = () => {
  const deviceInfo = {
    isMobile: isMobile,
    isAndroid: isAndroid,
    osName: osName,
    isIOS: isIOS,
    mobileModel: mobileModel,
    osVersion: osVersion,
    browserName: browserName,
    deviceType: deviceType,
    mobileVendor: mobileVendor,
    engineName: engineName,
    isDesktop: isDesktop,
    isTablet: isTablet,
    fullBrowserVersion: fullBrowserVersion,
  };
  return deviceInfo;
};
