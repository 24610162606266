import React from "react";

export default function WhiteButton(props) {
  const submitChange = () => {
    if (props.submitChange) props.submitChange();
  };
  return (
    <>
      <button
        type="button"
        className="btn whiteButton paddingbutton whiteText d-flex align-items-center justify-content-center"
        onClick={submitChange}
      >
        {props.buttonText}
      </button>
    </>
  );
}
