import React, { useRef, useState, useEffect } from "react";
import Logo from "../../Images/Logo/logoFooter.svg";
import LinkedIn from "../../Images/Image/LinkedIn.svg";
import { Link } from "react-router-dom";

export default function Footer(props) {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const Offerings = () => {
    setTimeout(() => {
      if (document.getElementById("Offerings")) {
        var elmntToView = document.getElementById("Offerings");
        elmntToView.scrollIntoView();
      }
    }, 200);
  };

  const FAQS = () => {
    setTimeout(() => {
      if (document.getElementById("FAQsS")) {
        var elmntToView = document.getElementById("FAQsS");
        elmntToView.scrollIntoView({ block: "start" });
      }
    }, 200);
  };
  return (
    <>
      <div
        className="footerColor paddingLR3P"
        style={{ backgroundColor: "#090d1f" }}
      >
        <footer className="py-4 ">
          <div className="row row-cols-1 row-cols-md-3 g-0 mt-3 pb-4">
            <div className="col">
              <div className="nav justiStartCenter ">
                <Link to="/" className="" onClick={() => scrollTop()}>
                  <div className="footerlogoBox">
                    <img src={Logo} className=" footerimageLogo" alt="..." />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col">
              <ul className="nav justify-content-center  ">
                <li className="nav-item px-3">
                  <Link
                    to="/"
                    className="  footerMenuText"
                    onClick={() => Offerings()}
                  >
                    Products
                  </Link>
                </li>
                <li className="nav-item px-3">
                  <Link
                    to="/blogs"
                    className=" footerMenuText"
                    onClick={() => scrollTop()}
                  >
                    Blogs
                  </Link>
                </li>
                <li className="nav-item px-3">
                  <Link
                    to="/contact_us"
                    className="  footerMenuText"
                    onClick={() => scrollTop()}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item px-3">
                  <Link
                    to="/resources"
                    className="footerMenuText"
                    // onClick={() => FAQS()}
                    onClick={() => scrollTop()}
                  >
                    Resources
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col">
              <div className="nav justiEndCenter">
                {/* <div className="mx-2">
                  <Link href="/" className="">
                    <img src={Twitter} className="" alt="..." />
                  </Link>
                </div> */}
                <div>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/spotquestai"
                    className=""
                    rel="noreferrer"
                  >
                    <img src={LinkedIn} className="" alt="..." />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between justCenter align-items-center py-3 my-4 border-top">
            <div className="col-md-4 d-flex align-items-center">
              <div className=" me-2 mb-md-0 text-decoration-none lh-1">
                <span className="mb-3 mb-md-0 textFooterBottom">
                  © 2023 Spotquest. All rights reserved.
                </span>
              </div>
            </div>

            {/* <ul className="nav col-md-4 justify-content-end justCenter list-unstyled d-flex">
              <li className="ms-3">
                <span className="mb-3 me-2 mb-md-0 text-decoration-none lh-1">
                  <span className="mb-3 mb-md-0 textFooterBottom">
                    Privacy Policy
                  </span>
                </span>
              </li>
              <li className="ms-3">
                <span className="mb-3 me-2 mb-md-0 text-decoration-none lh-1">
                  <span className="mb-3 mb-md-0 textFooterBottom">
                    Terms of Service
                  </span>
                </span>
              </li>
              <li className="ms-3">
                <span className="mb-3 me-2 mb-md-0 text-decoration-none lh-1">
                  <span className="mb-3 mb-md-0 textFooterBottom">
                    Cookies Settings
                  </span>
                </span>
              </li>
            </ul> */}
          </div>
        </footer>
      </div>
    </>
  );
}
