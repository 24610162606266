import React, { useEffect, useState } from "react";
import Logo from "../../Images/Logo/SpotQuest_Logo.svg";
import { Link } from "react-router-dom";
import WhiteButton from "../Button/WhiteButton";

export default function NavBar(props) {
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive("");
    if (window.location.pathname === "/") {
      setActive("landing");
    } else if (window.location.pathname === "/blogs") {
      setActive("blog");
    } else if (window.location.pathname === "/contact_us") {
      setActive("contact");
    } else if (window.location.pathname === "/sq-maps") {
      setActive("sq-maps");
    } else if (window.location.pathname === "/resources") {
      setActive("resources");
    } else if (
      window?.location?.pathname?.split("/")[3] === "blogmore" ||
      window?.location?.pathname?.split("/")[1] === "blogmore"
    ) {
      setActive("blog");
    } else {
      setActive("");
    }
  }, [window.location.pathname]);

  const Offerings = () => {
    setTimeout(() => {
      if (document.getElementById("Offerings")) {
        var elmntToView = document.getElementById("Offerings");
        elmntToView.scrollIntoView();
      }
    }, 200);
  };

  return (
    <div className="h-vh-8 ">
      <nav className="navbar navbar-expand-lg navBarBGColor ">
        <div className="container-fluid paddingLR3P">
          <a className="" href="/">
            <img src={Logo} alt="logo" className="logoHight" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end align-items-center"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav my-auto mb-2 mb-lg-0 align-items-center">
              <li className="nav-item  navHoverButton">
                <Link
                  className={
                    "nav-link  fnt16600 paddingLR10 paddingbutton " +
                    (active === "landing" ? "navactive" : null)
                  }
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item  dropdown">
                <Link
                  className="nav-link  fnt16600 paddingLR10 paddingbutton"
                  aria-current="page"
                  to="/"
                  onClick={() => Offerings()}
                >
                  Products
                </Link>
                {/* <ul
                  className="dropdown-menu p-0 border0px"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item ">
                <Link
                  className={
                    "nav-link  fnt16600 paddingLR10 paddingbutton " +
                    (active === "sq-maps" ? "navactive" : null)
                  }
                  to="/sq-maps"
                >
                  SQ-Maps
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className={
                    "nav-link  fnt16600 paddingLR10 paddingbutton " +
                    (active === "resources" ? "navactive" : null)
                  }
                  to="/resources"
                >
                  Resources
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className={
                    "nav-link  fnt16600 paddingLR10 paddingbutton " +
                    (active === "blog" ? "navactive" : null)
                  }
                  to="/blogs"
                >
                  Blogs
                </Link>
              </li>

              <li className="nav-item hideOnSmall">
                <div className="blankLine"></div>
              </li>
              <li className="nav-item ">
                <Link
                  className={
                    "nav-link  fnt16600 paddingLR10 paddingbutton " +
                    (active === "contact" ? "navactive" : null)
                  }
                  to="/contact_us"
                >
                  Contact Us
                </Link>
              </li>

              {/* <li className="nav-item p-0">
                <Link className="nav-link p-0 font16black600 " to="/contact_us">
                  <WhiteButton buttonText="Contact Us" />
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
