import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetBlog } from "../../Redux/Actions/Action";
import { connect } from "react-redux";
import User from "../../Images/Image/User.svg";
import moment from "moment";
import Spinner from "../../util/Spinner";
import BlogPage from "./Components/BlogPage";
import cardLinkMore from "../../Images/Image/cardLinkMore.svg";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import BlackButton from "../../Components/Button/BlackButton";
import BlueBox from "../../Components/BlueBox/BlueBox";
import TopBlogMini from "./Components/TopBlogMini";

function Blog(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let data = {
      type: "type_sqp"
    };
    if (!props.blogs) props.GetBlog(data);
  }, [props.blogs]);

  const routeChange = (e) => {
    const path = `/blogmore/${e}`;
    navigate(path);
  };
  const submitChange = (e) => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="">
      <NavBar />
      <div className="paddingLR3P" style={{ backgroundColor: "#fff" }}>
        {!props?.blogs ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-start align-items-center w-100 mt-5 pt-5 ">
              <div className="">
                <div className=" w-100 font16gray2600 ">Blog</div>
                <div className="text-center d-flex justify-content-start align-items-center pb-4">
                  <div className=" w-75">
                    <h2 className="font48medias text-start">
                      Stay Updated: Engage with Our Informative Blog Content
                    </h2>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-5 pb-4">
                  <div className="font18black400 text-start">
                    Track all the latest developments and news about SpotQuest .
                  </div>
                </div>
              </div>
            </div>
            <TopBlogMini blogs={props.blogs} routeChange={routeChange} />
          </>
        )}
        <div className="pt-4">
          <div className="pt-5 pb-4">
            <p className="font36black700">Featured blog posts</p>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-4  mb-4 pb-5">
            {!props?.blogs ? (
              <Spinner />
            ) : (
              <>
                <BlogPage
                  blogs={props.blogs.slice(4, props.blogs.length)}
                  routeChange={routeChange}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <BlueBox />
      <Footer />
    </div>
  );
}

const stateToProps = (state) => {
  const { parentState } = state;
  return {
    blogs: parentState.blogs
  };
};

export default connect(stateToProps, {
  GetBlog
})(Blog);
