import React from "react";
import { useNavigate } from "react-router-dom";
import BlackButton from "../Button/BlackButton";

export default function BlueBox() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate(`/contact_us`);
  };
  return (
    <div className="allplatformBGcolor py-5">
      <div className=" d-flex justify-content-center">
        <div className="w-75 ">
          <div className="py-5 my-4 ">
            <div className="d-flex justify-content-center align-items-center pb-4 px-4">
              <h1 className="font36black600 textaline">
                All-in-one platform for business
              </h1>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-4 px-5 padding0media">
              <p className="text-center font16gray400 px-5 mx-5 padding0media">
                SpotQuest helps restaurants and Hotels to take informed
                decisions and gain a competitive edge by providing all round
                location, pricing and competition assessment.
              </p>
            </div>
            <div className=" d-flex justify-content-center align-items-center">
              <BlackButton
                buttonText="Contact Us"
                submitChange={submitChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
