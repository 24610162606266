import React from "react";
import { useNavigate } from "react-router-dom";
import BlackButton from "../Button/BlackButton";
import Img from "../../Images/Image/landingBottom.svg";
import rectang from "../../Images/Image/Rectangle.svg";

export default function GrowBetter() {
  const navigate = useNavigate();
  const submitChange = (e) => {
    navigate(`/contact_us`);
  };
  return (
    <>
      <div style={{ backgroundColor: "#E0F2FE" }} className=" pt-3">
        <div className="row row-cols-1 row-cols-md-2 g-0 w-100 ">
          <div className="col paddingLR3P d-flex justify-content-center align-items-center">
            <div className="">
              <h1 className="font36black700 pb-3">
                Increase your competitive edge with SpotQuest today
              </h1>
              <p className="font16black400 pb-4">
                Accelerate your decision making using SpotQuest and see your
                business fly.
              </p>
              <BlackButton
                buttonText="Contact Us"
                submitChange={submitChange}
              />
            </div>
          </div>
          {/* <div className="col mt-5">
            <div className="postionre">
              <img src={rectang} className="imgs " />

              <div className="postionAb w-100">
                <div className="d-flex flexmedia flex1 g-0 ">
                  <div className=" col-md-5 ">
                    <div className="padinfLeft">
                      <div className="px-4 ">
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Faster Decision Making
                          </p>
                        </div>
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Quantitative Location Assessment
                          </p>
                        </div>
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Automated and Customizable
                          </p>
                        </div>
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Holistic Prospective
                          </p>
                        </div>
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Highly Relevant
                          </p>
                        </div>
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Confidence Decision Making
                          </p>
                        </div>
                        <div className="ButtonBorder mb-3">
                          <p className="buttonTextBorder text-start">
                            Ahead of the Competition
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-7  padingLeft">
                    <img src={Img} className="imgs" />
                  </div>
                </div>
              </div>
            </div>
           
          </div> */}

          <div className="col growColor">
            <div className="linerline postionre ">
              <div className="d-flex flexmedia flex1 g-0 postionAb">
                <div className=" col-md-5 ">
                  <div className="padinfLeft">
                    <div className="px-4 ">
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Faster Decision Making
                        </p>
                      </div>
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Location Assessment
                        </p>
                      </div>
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Automated and Customizable
                        </p>
                      </div>
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Holistic Perspective
                        </p>
                      </div>
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Highly Relevant
                        </p>
                      </div>
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Confidence Decision Making
                        </p>
                      </div>
                      <div className="ButtonBorder mb-3">
                        <p className="buttonTextBorder text-start">
                          Ahead of the Competition
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-md-7  padingLeft">
                  <img src={Img} className="imgs" alt="demo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
