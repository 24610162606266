import React, { useEffect, useState } from "react";
import Email from "../../Images/Image/sms.svg";
import call from "../../Images/Image/call.svg";
import location from "../../Images/Image/location.svg";
import ContactUsForm from "./ContactUsForm";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";

export default function ContactUs(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-vh-100 bgPage">
      <NavBar />
      <div className="h-vh-92" style={{ backgroundColor: "#fff" }}>
        <div className="row row-cols-1 row-cols-md-3 g-0 contactFirstBg2 paddingLR3P  py-5">
          <div className="col d-flex justify-content-center align-items-center py-3 ">
            <div>
              <div className="d-flex justify-content-center align-items-center ">
                <img src={Email} className="" alt="..." />
              </div>
              <div className="my-2">
                <p className="contactEmailText">Email</p>
              </div>
              <div>
                <p className="contactEmailId">hannes@spotquest.ai</p>
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <div>
              <div className="d-flex justify-content-center align-items-center ">
                <img src={call} className="" alt="..." />
              </div>
              <div className="my-2">
                <p className="contactEmailText">Phone</p>
              </div>
              <div>
                <p
                  className="contactEmailId"
                  style={{ textDecoration: "none" }}
                >
                  +971547481121
                </p>
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <div>
              <div className="d-flex justify-content-center align-items-center ">
                <img src={location} className="" alt="..." />
              </div>
              <div className="my-2">
                <p className="contactEmailText">Office</p>
              </div>
              <div>
                <p
                  className="contactEmailId"
                  style={{ textDecoration: "none" }}
                >
                  11 Irving Place, #09-01 Singapore, 369551
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="paddingLR3P py-4">
          <div className="pading20 pading20media  py-5">
            <ContactUsForm
              pageName="Contact us"
              SubTitleName="Got Questions? We've Got Answers! (And an Enquiry Form)"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
