import React from "react";
import { Link } from "react-router-dom";
import lexiconLogo from "../../Images/Image/lexiconLogo.svg";
import PartnerLogo from "../../Images/Image/PartnerLogo.svg";
import metapoint from "../../Images/Image/metapoint.png";
export default function OurPartners() {
  return (
    <>
      <div className="">
        <div className="py-5" style={{ backgroundColor: "#fff" }}>
          <div className="d-flex justify-content-center align-items-center py-4 pt-5">
            <p className=" font16black600">Our Partners</p>
          </div>
          <div className="d-flex justify-content-center align-items-center ">
            <div className="row row-cols-1 row-cols-md-3 g-0 pb-5 d-flex justify-content-center align-items-center">
              {/* <div className="flexmedia justify-content-center align-items-center  pb-4"> */}
              <Link
                target="_blank"
                to="https://www.lexicon-partners.com"
                className="d-flex justify-content-center align-items-center width250"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="col mx-4 logoBgcolor  width250 marBotMedia p-2 px-3">
                  <img src={lexiconLogo} className="imgs" alt="..." />
                </div>
              </Link>
              <Link
                target="_blank"
                to="https://www.thecuttingedgeagency.com"
                className="d-flex justify-content-center align-items-center width250"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="col mx-4  d-flex justify-content-center width250 marBotMedia p-2 px-3">
                  <img src={PartnerLogo} className="imgs3" alt="..." />
                </div>
              </Link>
              <Link
                target="_blank"
                to="https://metapointadvisory.com"
                className="d-flex justify-content-center align-items-center width250"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className=" col  mx-4 width250 d-flex justify-content-center logoBgcolor2 marBotMedia p-2">
                  <img src={metapoint} className="imgs3" alt="..." />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
