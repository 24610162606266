import React from "react";
import cardLinkMore from "../../../Images/Image/cardLinkMore.svg";

export default function TopBlogMini(props) {
  const routeChange = (e) => {
    props.routeChange(e);
  };
  return (
    <div>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {props?.blogs?.[0] && (
          <div className="col">
            <div className="card topCardHeight ">
              <img
                src={props?.blogs?.[0]?.titleimage}
                className="card-img-top topCardImageHeight cardHover"
                alt="..."
                onClick={(e) => routeChange(props?.blogs?.[0]?.Id)}
              />
              <div className="card-body px-0">
                <h5 className="card-title textOverFlow2Line font30black700">
                  {" "}
                  {props?.blogs?.[0]?.title}
                </h5>
                <p className="card-text textOverFlow font16gray400">
                  {props?.blogs?.[0]?.summery}
                </p>
              </div>
              
              <div
                className="card-link cardLink readMehover"
                style={{ cursor: "pointer" }}
                onClick={(e) => routeChange(props?.blogs?.[0]?.Id)}
              >
                Read more
                <img src={cardLinkMore} className="" alt="..." />
              </div>
            </div>
          </div>
        )}
        <div className="col ">
          {props?.blogs?.[1] && (
            <div className="row mb-3">
              <div className="col-md-4">
                <img
                  src={props?.blogs?.[1]?.titleimage}
                  className="img-fluid  miniCardImgHeight cardHover"
                  alt="..."
                  onClick={(e) => routeChange(props?.blogs?.[1]?.Id)}
                />
              </div>
              <div className="col-md-8 d-flex justify-content-start align-items-center">
                <div className="card-body">
                  <h5 className="card-title textOverFlow1Line font24black700">
                    {props?.blogs?.[1]?.title}
                  </h5>
                  <p className="card-text textOverFlow font16gray400">
                    {props?.blogs?.[1]?.summery}
                  </p>
                  <div
                    className="card-link cardLink readMehover "
                    style={{ cursor: "pointer" }}
                    onClick={(e) => routeChange(props?.blogs?.[1]?.Id)}
                  >
                    Read more
                    <img src={cardLinkMore} className="" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          )}
          {props?.blogs?.[2] && (
            <div className="row mb-3">
              <div className="col-md-4">
                <img
                  src={props?.blogs?.[2]?.titleimage}
                  className="img-fluid  miniCardImgHeight cardHover"
                  alt="..."
                  onClick={(e) => routeChange(props?.blogs?.[2]?.Id)}
                />
              </div>
              <div className="col-md-8 d-flex justify-content-start align-items-center">
                <div className="card-body">
                  <h5 className="card-title textOverFlow1Line font24black700">
                    {" "}
                    {props?.blogs?.[2]?.title}
                  </h5>
                  <p className="card-text textOverFlow font16gray400">
                    {props?.blogs?.[2]?.summery}
                  </p>
                  <div
                    className="card-link cardLink readMehover"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => routeChange(props?.blogs?.[2]?.Id)}
                  >
                    Read more
                    <img src={cardLinkMore} className="" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          )}
          {props?.blogs?.[3] && (
            <div className="row ">
              <div className="col-md-4">
                <img
                  src={props?.blogs?.[3]?.titleimage}
                  className="img-fluid  miniCardImgHeight cardHover"
                  alt="..."
                  onClick={(e) => routeChange(props?.blogs?.[3]?.Id)}
                />
              </div>
              <div className="col-md-8 d-flex justify-content-start align-items-center">
                <div className="card-body ">
                  <h5 className="card-title textOverFlow1Line font24black700">
                    {" "}
                    {props?.blogs?.[3]?.title}
                  </h5>
                  <p className="card-text textOverFlow font16gray400">
                    {props?.blogs?.[3]?.summery}
                  </p>
                  <div
                    className="card-link cardLink readMehover"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => routeChange(props?.blogs?.[3]?.Id)}
                  >
                    Read more
                    <img src={cardLinkMore} className="" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
