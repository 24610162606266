import React, { useState } from "react";
import TGMAPIMG1 from "../../../Images/Logo/Pic1.png";
import TGMAPIMG2 from "../../../Images/Logo/Pic2.png";
import TGMAPIMG3 from "../../../Images/Logo/Pic3.png";
import WhiteButton from "../../../Components/Button/WhiteButton";
import { Link } from "react-router-dom";
import Documentations from "./Documentations";
import NavBar from "../Navbar";

const MainView = (props) => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);

  return (
    <React.Fragment>
      <NavBar
        openLogin={openLogin}
        closeLogin={() => setOpenLogin(false)}
        openSignup={openSignup}
        closeSignup={() => setOpenSignup(false)}
      />
      <div className="main-head">
        <span className="text-con-head">
          Add Immersive SQ-Maps to your Web and Mobile apps for enhanced
          Customer Engagement.
        </span>
      </div>
      <div className="main-view">
        <div className="main-left">
          <div className="text-container">
            <span className="con-text">
              Most relevant and accurate maps for travel and hospitality
              businesses. Immersive, interactive and curated travel content for
              travellers to make their next trip remarkable. Experience
              increased engagement and better conversion rates through increased
              visibility and information from SpotQuest-Maps.
            </span>
            {/* <Link to={"/tg-maps/doc"} style={{ textDecoration: "none" }}> */}
            {localStorage.getItem("name") ? null : (
              <WhiteButton
                buttonText="Login"
                submitChange={() => setOpenLogin(true)}
              />
            )}
            {/* </Link> */}
          </div>
        </div>
        <div className="main-rgiht">
          <div className="image-container">
            <img src={TGMAPIMG1} alt="maps" />
          </div>
        </div>
      </div>

      <div className="main-view">
        <div className="main-rgiht">
          <div className="image-container">
            <img src={TGMAPIMG2} alt="maps" />
          </div>
        </div>
        <div className="main-left">
          <div className="text-container">
            <span className="text-con-head">What are SQ-Maps?</span>
            <span className="con-text" style={{ fontSize: 22 }}>
              SQ-Maps is a Javascript SDK(software development kit) that allows
              travel and hospitality businesses to integrate our Inhouse built
              map components and functionalities into their web and mobile
              applications. Our carefully curated maps with just the right data
              and information for travelers to make their travel experience
              perfect.
            </span>
          </div>
        </div>
      </div>

      <div className="main-view">
        <div className="main-left">
          <div className="text-container">
            <span className="text-con-head">How can I use SQ-Maps?</span>
            <span className="con-text" style={{ fontSize: 22 }}>
              Businesses can use SQ-Maps by{" "}
              <span
                onClick={() => setOpenSignup(true)}
                style={{ color: "#0d6efd", cursor: "pointer" }}
              >
                signing up
              </span>{" "}
              on the platform and generate an enterprise key. Usage is billed on
              per map load basis. To get a detailed pricing quote,{" "}
              <Link to="/contact_us">Contact Us</Link>. Following is a complete
              documentation to use SQ-Maps.
            </span>
          </div>
        </div>
        <div className="main-rgiht">
          <div className="image-container">
            <img src={TGMAPIMG3} alt="maps" />
          </div>
        </div>
      </div>

      <Documentations />
    </React.Fragment>
  );
};

export default MainView;
