import React, { useEffect, useState } from "react";
import { Form, Input, Tooltip, message } from "antd";
import {
  InfoCircleOutlined,
  UserOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import "antd/dist/reset.css";
import WhiteButton from "../../../Components/Button/WhiteButton";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../util/Spinner";
import { Loginuser, DeviceInfo } from "../../../Redux/Actions/Action";

const LoginForm = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [spin, setSpin] = useState(false);

  const inputHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onFinish = async () => {
    setSpin(true);
    const res = await Loginuser({ ...state, device: DeviceInfo() });
    setSpin(false);
    if (res.error) {
      message.error(res.response);
    } else {
      localStorage.setItem("token", res?.user_key);
      localStorage.setItem("name", res?.name);
      props.close();
      setState({
        email: "",
        password: "",
      });
      message.success("Login Successfully!!!");
      // navigate("/sq-maps");
    }
  };

  return (
    <React.Fragment>
      {spin ? <Spinner /> : null}
      <div className="main-view" style={{ borderRadius: 20 }}>
        <div className="main-left">
          <Form
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="form-container"
          >
            <Input
              className="form-item-input"
              type="email"
              placeholder="Enter your email"
              prefix={<UserOutlined className="site-form-item-icon" />}
              suffix={
                <Tooltip title="Enter your email address">
                  <InfoCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                </Tooltip>
              }
              value={state.email}
              name="email"
              onChange={inputHandler}
            />

            <Input.Password
              className="form-item-input"
              placeholder="Enter your password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              suffix={
                <Tooltip title="Enter your password">
                  <InfoCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                </Tooltip>
              }
              value={state.password}
              name="password"
              onChange={inputHandler}
            />

            <Form.Item>
              <WhiteButton buttonText="Login" submitChange={onFinish} />
            </Form.Item>
          </Form>
          <div className="already-login">
            <span className="all-spf">
              Don't have an account?{" "}
              <span onClick={() => props.signup()} className="al-sign-spf">
                Signup
              </span>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
