import React, { useState, useEffect } from "react";
import { polyService } from "../../Redux/Service/Service";
import { message } from "antd";
import Spinner from "../../util/Spinner";
import BlackButton from "../../Components/Button/BlackButton";

export default function ContactUsForm(props) {
  const [contactData, setContactData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyNames: "",
    jobrole: "",
    message: ""
  });
  const [loginSpiner, setLoginSpiner] = useState(false);

  const submitChange = async (e) => {
    console.log(contactData.jobrole);
    setLoginSpiner(true);
    if (
      contactData.email !== "" &&
      contactData.firstName !== "" &&
      contactData.companyNames !== "" &&
      contactData.jobrole !== ""
    ) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactData.email)) {
        const data = {
          email: contactData.email,
          name: contactData.firstName,
          companyName: contactData.companyNames,
          jobrole: contactData.jobrole,
          message: contactData.message,
          date: new Date()
        };

        const result = await polyService.contactUs(data);
        if (result.status === 200) {
          setLoginSpiner(false);
          message.success("Thank you for contacting us.");
          setContactData({ ...contactData, errorMesg: "tnx" });
          cleanAllData();
          if (props.resource) {
            props.DownloadReport();
          }
        } else {
          message.error("Please try again!");
          setLoginSpiner(false);
        }
      } else {
        message.error("Please Enter Valid Email Id.");
        setLoginSpiner(false);
      }
    } else {
      message.error("Please fill out all required fields.");
      setLoginSpiner(false);
    }
  };
  useEffect(() => {
    cleanAllData();
  }, [props.cleanAllTure]);
  const cleanAllData = () => {
    setContactData({
      email: "",
      firstName: "",
      lastName: "",
      companyNames: "",
      jobrole: "",
      message: ""
    });
  };
  const handleChangeContactCheck = (event) => {
    setContactData({ ...contactData, jobrole: event.target.value });
  };

  const handleChangeContact = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  return (
    <div className="">
      <div className="" id="my-id">
        {!props.resource && (
          <div className="pb-4">
            <p className="font36black700 text-center">{props.pageName}</p>
          </div>
        )}
        <div>
          <p className="font16black400 text-center pb-4">
            {props.SubTitleName}
          </p>

          <div className="row row-cols-1 row-cols-md-2 g-4 my-3">
            <div className="col">
              <label for="exampleInputFirst" class="form-label font16black600">
                Full name *
              </label>
              <input
                type="text"
                class="form-control inputBox"
                placeholder="Full name"
                aria-label="First name"
                name="firstName"
                onChange={handleChangeContact}
                value={contactData.firstName}
              />
            </div>
            <div className="col">
              <label for="exampleInputLast" class="form-label font16black600">
                Company *
              </label>
              <input
                type="text"
                class="form-control inputBox"
                placeholder="Company Name "
                aria-label="Company Name"
                name="companyNames"
                onChange={handleChangeContact}
                value={contactData.companyNames}
              />
            </div>
          </div>

          <div className="mb-3 my-3">
            <label for="exampleInputEmail1" class="form-label font16black600">
              Email address *
            </label>
            <input
              onChange={handleChangeContact}
              value={contactData.email}
              placeholder="Email"
              type="email"
              name="email"
              class="form-control inputBox"
              id="exampleInputEmail1"
            />
          </div>

          <div className="mt-4">
            <p className="font16black600 pb-2">
              What describes your job role best? *
            </p>
          </div>

          <div onChange={handleChangeContactCheck.bind()}>
            <div className="form-check py-1">
              <input
                checked={contactData.jobrole == "C-level" ? true : false}
                class="form-check-input"
                type="radio"
                id="exampleRadios1"
                name="job"
                value="C-level"
              />
              <label class="form-check-label" for="exampleRadios1">
                C-level
              </label>
            </div>
            <div className="form-check py-1">
              <input
                checked={
                  contactData.jobrole == "VP/GM and Above" ? true : false
                }
                class="form-check-input"
                type="radio"
                id="exampleRadios2"
                name="job"
                value="VP/GM and Above"
              />
              <label class="form-check-label" for="exampleRadios2">
                VP/GM and Above
              </label>
            </div>
            <div className="form-check py-1">
              <input
                checked={contactData.jobrole == "Manager" ? true : false}
                class="form-check-input"
                type="radio"
                id="exampleRadios3"
                name="job"
                value="Manager"
              />
              <label class="form-check-label" for="exampleRadios3">
                Manager
              </label>
            </div>
            <div className="form-check py-1">
              <input
                checked={contactData.jobrole == "Executive" ? true : false}
                class="form-check-input"
                type="radio"
                id="exampleRadios4"
                name="job"
                value="Executive"
              />
              <label class="form-check-label" for="exampleRadios4">
                Executive
              </label>
            </div>
          </div>

          <div className="mb-3 my-3">
            <label
              for="exampleFormControlTextarea1"
              class="form-label font16black600"
            >
              Message
            </label>
            <textarea
              placeholder="Type your message..."
              class="form-control inputBox"
              id="exampleFormControlTextarea1"
              onChange={handleChangeContact}
              name="message"
              value={contactData.message}
              rows="4"
            ></textarea>
          </div>
          <div className=" d-flex justify-content-center align-items-center mt-5 py-4">
            <BlackButton
              buttonText={
                loginSpiner ? (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{
                      width: 25,
                      height: 25,
                      borderWidth: 2
                    }}
                  ></div>
                ) : (
                  "Submit"
                )
              }
              submitChange={submitChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
