import React from "react";
// import BlackButton from "../Button/BlackButton";
import { useNavigate } from "react-router-dom";
import BlackButton from "../Button/BlackButton";

export default function OurProducts() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate("/contact_us");
  };
  return (
    <>
      <div
        id="Offerings"
        className="d-flex justify-content-center align-items-center w-100 py-5 paddingLR3P"
        style={{ backgroundColor: "#F3F4F6" }}
      >
        <div className="py-4 my-2">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Our Products
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font48black700 color1F2937">
              Explore our revolutionary location intelligence platforms
            </div>
          </div>

          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 fontUserName">
              Game-changing global data with actionable insights for the
              hospitality industry - create competitve strategies, improve
              profitability and grow market share
            </div>
          </div>
          <div className="text-center d-flex justify-content-center align-items-center">
            <div className="mx-2">
              <BlackButton
                buttonText="Get In Touch"
                submitChange={submitChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
