import React, { useState, useEffect } from "react";

import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import Hotel from "../../Images/Image/Hotel.svg";
import Res from "../../Images/Image/Restaurant.svg";
import Frame from "../../Images/Image/resource.jpg";
import DownloadButton from "../../Components/Button/DownloadButton";
import ContactUsForm from "../ContactUs/ContactUsForm";

export default function Download(props) {
  const [clean, setClean] = useState(false);
  const [buttonClickData, SetButtonClickData] = useState("");

  const DownloadButtonReport = (e) => {
    if (buttonClickData !== "") {
      var dlink = document.createElement("a");
      dlink.download = "sample.pdf";
      dlink.textContent = "download";
      dlink.href = buttonClickData;
      dlink.target = "_blanck";
      dlink.click();
    }
    document.getElementById("closeModal").click();
  };

  const buttonClick = (e) => {
    SetButtonClickData(e);
  };

  const cleanAll = (e) => {
    setClean(!clean);
  };
  return (
    <div>
      <NavBar />
      <h1 className="font48black700  text-center py-5"> Resources</h1>
      <div className="d-flex justify-content-center align-items-center container1 ">
        <img src={Frame} className="imgsss1 " alt="..." />
      </div>

      <div className="paddingLR3P  py-5">
        <div className="row row-cols-1 row-cols-md-1 g-4 pb-5">
          <div className="col blogCardBox mb-2">
            <div className="">
              <div className="card  borderNone ">
                <div className="card-body d-flex flexmedia justify-content-between align-items-center">
                  <div className="d-flex justify-content-start justCenter align-items-center paddingBott15">
                    <img src={Res} alt="" className="downloadimage" />
                    <div className="px-2 ">
                      <h5
                        className="font30black700 "
                        style={{ fontSize: 20, margin: 0 }}
                      >
                        SpotQuest for Restaurants
                      </h5>
                      <p class="card-text ">
                        <small class="text-muted">
                          Sample report for restaurants
                        </small>
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      buttonClick(
                        "https://storage.googleapis.com/sq_pdf/SpotQuest%20for%20restaurants%20insight%20report%20Sample%20Report%20SpotQuest%20URL.pdf"
                      )
                    }
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    className="d-flex justify-content-start justCenter align-items-center"
                  >
                    <DownloadButton buttonText="Download" disable={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col blogCardBox mb-2">
            <div className="">
              <div className="card  borderNone ">
                <div className="card-body d-flex flexmedia justify-content-between align-items-center">
                  <div className="d-flex justify-content-start justCenter align-items-center paddingBott15">
                    <img src={Hotel} alt="" className="downloadimage" />
                    <div className="px-2 ">
                      <h5
                        className="font30black700 "
                        style={{ fontSize: 20, margin: 0 }}
                      >
                        SpotQuest for Hotels
                      </h5>
                      <p class="card-text ">
                        <small class="text-muted">
                        Sample report for hotels
                        </small>
                      </p>
                    </div>
                  </div>
                  <div
                    // onClick={() =>
                    //   buttonClick(
                    //     "https://storage.googleapis.com/sq_pdf/SpotQuest%20Insight%20Report%20for%20Asian%20Cuisine%20in%20Dubai%20SAMPLE%20REPORT.pdf"
                    //   )
                    // }
                    // type="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#staticBackdrop"
                    className="d-flex justify-content-start justCenter align-items-center"
                  >
                    <DownloadButton buttonText="Download" disable={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">

          </div>
        </div>
      </div>

      {/* -------------------------------Modal--------------------------*/}

      <div
        className="modal fade modal-xl"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-scrollable py-4">
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: 0 }}>
              <button
                type="button"
                id="closeModal"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={cleanAll}
              ></button>
            </div>
            <div className="modal-body">
              <div className="pading10 pading20media  pb-4">
                <ContactUsForm
                  DownloadReport={() => DownloadButtonReport()}
                  cleanAllTure={clean}
                  resource={true}
                  SubTitleName="Please fill out the form below to download report."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
