import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../Components/NavBar/NavBar";

export default function PageNotFound() {
  const navigate = useNavigate();

  const Home = (e) => {
    navigate(`/`);
  };
  return (
    <>
      <div className="h-vh-100 bgPage">
        <NavBar a={true} />

        <div className="h-vh-92 " style={{ backgroundColor: "#fff" }}>
          <div className="py-4">
            <div className="row row-cols-1 row-cols-md-1 g-0">
              <div class="four_zero_four_bg">
                <h1 class="text-center ">404</h1>
              </div>
              <div class="text-center ">
                <h3 class="h2">Look like you're lost</h3>

                <p>the page you are looking for not avaible!</p>

                <button
                  type="submit"
                  className="btn SubmitButton fntW700 px-4"
                  onClick={() => Home()}
                >
                  Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
